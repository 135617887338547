import React from 'react';
import './style.scss';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <>
            <div className='sw-footer'>
                <div className='brand'>
                    <img
                        src={'https://sw-assets.gumlet.io/springworks/springworks-black.svg'}
                        data-src={'https://sw-assets.gumlet.io/springworks/springworks-black.svg'}
                        alt='Springworks Logo'
                        loading='lazy'
                    />
                </div>

                <div className='swFooterLinksWrap'>
                    <div className='swFooterLinksInner'>
                        <div className='swFooterLinksHeader'>
                            <h6>Company</h6>
                        </div>
                        <div className='swFooterLinks'>
                            <Link to='/'>Home </Link>
                            <Link to={'/about-us/'}>About Us </Link>
                            <a href={'/careers/'} target='_blank' rel='noreferrer noopener'>
                                Work with Us
                            </a>
                            <a href={'/giving-back/'} target='_blank' rel='noreferrer noopener'>
                                Community Initiatives
                            </a>
                            <a href={'/privacy-policy/'} target='_blank' rel='noreferrer noopener'>
                                Privacy Policy
                            </a>
                            <a href={'/tnc/'} target='_blank' rel='noreferrer noopener'>
                                Terms & Conditions
                            </a>
                            <a href='mailto:press@springworks.in'>Press Enquiries</a>
                        </div>
                    </div>

                    <div className='swFooterLinksInner'>
                        <div className='swFooterLinksHeader'>
                            <h6>Resources</h6>
                        </div>
                        <div className='swFooterLinks'>
                            <a href='https://www.springworks.in/blog/' target='_blank' rel='noreferrer noopener'>
                                Blog
                            </a>
                            <a
                                href={'https://www.springworks.in/the-shape-of-work-podcast/'}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                The Shape of Work Podcast
                            </a>
                            <a
                                href={'https://www.springworks.in/the-shape-of-work-podcast/community/'}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                HR Community
                            </a>
                            <a href={'/employee-wellness-program/'} target='_blank' rel='noreferrer noopener'>
                                Employee Wellness Program
                            </a>
                            <a href='https://www.springworks.in/webinars/' target='_blank'>
                                Webinars
                            </a>
                        </div>
                    </div>

                    <div className='swFooterLinksInner'>
                        <div className='swFooterLinksHeader'>
                            <h6>Guides</h6>
                        </div>
                        <div className='swFooterLinks'>
                            <a
                                href='https://www.springworks.in/blog/employee-engagement-guide/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Employee Engagement
                            </a>
                            <a
                                href='https://www.springworks.in/blog/employee-rewards-and-recognition-ideas/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Employee Rewards & Recognition ideas
                            </a>
                            <a
                                href='https://www.springworks.in/blog/team-building-guide/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Team Building Activities
                            </a>
                            <a
                                href='https://www.springworks.in/blog/applicant-tracking-systems-guide/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Application Tracking Systems
                            </a>
                            <a
                                href='https://www.springworks.in/blog/employee-background-check-guide/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Background Screening
                            </a>
                        </div>
                    </div>

                    <div className='swFooterLinksInner'>
                        <div className='swFooterLinksHeader'>
                            <h6>Products</h6>
                        </div>
                        <div className='swFooterLinks'>
                            <Link to={'/trivia/'}>Real-time Quizzes & Games</Link>
                            <Link to={'/engagewith/'}>Employee Rewards & Recognition</Link>
                            <a href='https://springverify.com/' target='_blank' rel='noreferrer noopener'>
                                Background Verification
                            </a>
                            <a href='https://springrecruit.com/' target='_blank' rel='noreferrer noopener'>
                                Free Applicant Tracking System
                            </a>
                            <a href='https://springrole.com/' target='_blank' rel='noreferrer noopener'>
                                Create Verified Professional Profile
                            </a>
                            <a href='https://meetforslack.com/' target='_blank' rel='noreferrer noopener'>
                                Meet For Slack
                            </a>
                        </div>
                    </div>

                    <div className='swFooterLinksInner'>
                        <div className='swFooterLinksHeader'>
                            <h6>Office</h6>
                        </div>
                        <div className='swFooterLinks'>
                            <p>1447 2nd Street, Suite 200 Santa Monica, CA 90401 United States</p>
                        </div>
                    </div>
                </div>
                <div className='swFooterForm'>
                    <div className='swFooterFormInner'>
                        <div className='swFooterFormLeft'>
                            <h5>Subscribe to Springworks </h5>
                            <p>
                                Exclusive invites to webinars, highly curated eBooks and access to content for remote
                                work resources picked up by our editors
                            </p>
                            <form
                                action='https://springworks.us14.list-manage.com/subscribe?u=ca6975e5d8eb9feae8b42d901&id=d29f51722f'
                                method='post'
                                id='mc-embedded-subscribe-form'
                                name='mc-embedded-subscribe-form'
                                className='emailCtr'
                                target='_blank'
                                rel='noreferrer noopener'
                                noValidate
                            >
                                <div id='mc_embed_signup_scroll' className='d-flex justify-content-start form-input'>
                                    <input
                                        className='emailInput'
                                        name='EMAIL'
                                        type='email'
                                        id='mce-EMAIL'
                                        placeholder={'Email Address'}
                                        required
                                    />
                                    <div className='clear'>
                                        <input
                                            type='submit'
                                            value='SUBSCRIBE'
                                            name='subscribe'
                                            id='mc-embedded-subscribe'
                                            className='button'
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='swFooterFormRight'>
                            <h4>Follow us</h4>
                            <div className='swFooterFormSocial'>
                                <a
                                    href='https://twitter.com/springroleinc/'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                    name={'twitter'}
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/twitter-white.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/twitter-white.svg'}
                                        alt={'twitter'}
                                        loading='lazy'
                                    />
                                </a>
                                <a
                                    href='https://www.linkedin.com/company/springrole/mycompany/'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                    name={'linkedin'}
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/linkedin-white.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/linkedin-white.svg'}
                                        alt={'linkedin'}
                                        loading='lazy'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
