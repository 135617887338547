import React from 'react';
import './styles.scss';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/springworks/Navbar';
import Footer from '../../components/springworks/Footer';

const Tnc = () => {
    return (
        <>
            <Helmet>
                <title>Terms and Conditions | Springworks</title>
                <meta
                    data-react-helmet='true'
                    name='description'
                    content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
                />
                <meta property='og:locale' content='en_US' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='Terms and Conditions | Springworks' />
                <meta
                    property='og:description'
                    content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
                />
                <meta property='og:url' content='https://www.springworks.in/about-us/' />
                <meta property='og:site_name' content='Springworks' />
                <meta
                    property='og:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
                />
                <meta
                    property='og:image:secure_url'
                    content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
                />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='600' />
                <meta name='twitter:card' content='summary_large_image' />
                <meta
                    name='twitter:description'
                    content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
                />
                <meta name='twitter:title' content='Terms and Conditions | Springworks' />
                <meta name='twitter:creator' content='@springroleinc' />
                <meta
                    name='twitter:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
                />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='600' />
                <link rel='canonical' href='https://www.springworks.in/tnc/' />
            </Helmet>
            <div className='tnc-navbar'>
                <Navbar />
            </div>
            <div className='tnc-wrapper'>
                <h1>Terms of Service</h1>

                <div className='tnc-section-header'>
                    <h4>Effective Date: 27-Apr-2021</h4>
                    <p>
                        Please read these terms of service carefully as they form a contract between You and the SaaS
                        Provider (​SpringRole Inc hereinafter referred as Springworks, ​‘we’ ‘us’) and govern use of and
                        access to the Service(s) and Website by You, Your Affiliates, Users and End-Users. In the event
                        of a conflict between these terms of service and our Privacy Policy, these terms of service
                        shall prevail.
                    </p>
                    <p>
                        By accessing or using the service(s) or websites, or authorizing or permitting any User or
                        End-User to access or use the Service(s) or Websites, You agree to be bound by these Terms. If
                        You are entering into these Terms on behalf of a company, organization or another legal entity
                        (an “​Entity​”), You are agreeing to these Terms for that Entity and representing to Us that You
                        have the authority to bind such Entity and its Affiliates to these Terms, in which case the
                        terms, “You", “Your” or related capitalized terms used herein shall refer to such Entity and its
                        Affiliates. If You do not have such authority, or if You do not agree with these Terms, You must
                        not accept these Terms and may not access or use the Service(s) or Websites.
                    </p>
                </div>

                <div className='tnc-acceptance-table'>
                    <div className='tnc-acceptance-table-header'>
                        <span>SERVICE(S)</span>
                        <span>WEBSITE(S)</span>
                    </div>
                    <div className='tnc-acceptance-table-content'>
                        <span>SpringEngage</span>
                        <span>
                            <a href={'/engagewith/'} target='_blank' rel='noreferrer noopener'>
                                https://www.springworks.in/springengage/
                            </a>
                        </span>
                    </div>
                    <div className='tnc-acceptance-table-content'>
                        <span>Trivia</span>
                        <span>
                            <a href={'/trivia/'} target='_blank' rel='noreferrer noopener'>
                                https://www.springworks.in/trivia/
                            </a>
                        </span>
                    </div>
                    <div className='tnc-acceptance-table-content'>
                        <span>SpringRole</span>
                        <span>
                            <a href='https://springrole.com/' target='_blank' rel='noreferrer noopener'>
                                https://springrole.com/
                            </a>
                        </span>
                    </div>
                </div>

                <div className='tnc-wrap'>
                    <h3>
                        <span>1.</span>ACCEPTANCE
                    </h3>

                    <div className='tnc-wrap-content'>
                        <p>
                            <span>1)</span>The following Terms and Conditions, including any other terms and policies
                            referred to in these Terms and Conditions, form the agreement between Spring Verify
                            (referred to as “Springworks”, “we” or “us”) and the user (referred to as “customer” or
                            “you”), who are collectively referred to as the Parties or each a Party, to this
                            “agreement”.
                        </p>
                        <p>
                            <span>2)</span>We own, or hold the relevant rights to the Software and will license the use
                            of the Software as a service to the customer.
                        </p>
                        <p>
                            <span>3)</span>This agreement sets out the terms upon which Springworks has agreed to grant
                            a license to the customer to use the SaaS Services. This agreement is binding on any use of
                            the Services and applies to the customer from the time that We provide the customer with an
                            access and use of the Services from Effective Date.
                        </p>
                        <p>
                            <span>4)</span>By accessing and/or using the Services you:
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span>warrant to us that you have reviewed this agreement, including our
                                Privacy Policy (available on the Site), with your parent or legal guardian (if you are
                                under 18 years of age), and you understand it well;
                            </p>
                            <p>
                                <span>b)</span>warrant to us that you have all the legal capacity to enter into this
                                legally binding agreement with Us or (if you are under 18 years of age) you have
                                obtained your parent’s or legal guardian’s permission to access and use the Site and
                                they have agreed to the Terms and Conditions provided hereunder, on your behalf; and
                            </p>
                            <p>
                                <span>c)</span>agree to use the Services in accordance with the terms as set out under
                                this agreement.
                            </p>
                        </div>
                        <p>
                            <span>5)</span>You are required to have attained 18 years of age to create a customer
                            account with us. If you are a parent or legal guardian permitting a person who is at least
                            13 years of age but under 18 years of age (a Minor) to create a customer account and/or use
                            the Site, you agree to:
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span>provide all the required supervision over the Minor’s use of the Site and
                                their account;
                            </p>
                            <p>
                                <span>b)</span>assume all risks associated with, and liabilities resulting from, the
                                Minor’s use of the Site and their customer account;
                            </p>
                            <p>
                                <span>c)</span>ensure user discretion that the content on the Site is suitable for the
                                Minor;
                            </p>
                            <p>
                                <span>c)</span>ensure all information submitted to us by the Minor is accurate; and
                            </p>
                            <p>
                                <span>c)</span>provide on behalf of the Minor, all the consents, representations and
                                warranties contained in these Terms and Conditions.
                            </p>
                        </div>
                        <p>
                            <span>6)</span> By using our Services and subscribing on our Site, you acknowledge that you
                            have read, understood, and accepted this agreement and you have the authority to act on
                            behalf of any person or entity for whom you are using the Services, and you are deemed to
                            have agreed to this agreement on behalf of any entity for whom you use the Services.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>2.</span>DEFINITIONS
                        </h3>
                        <p>
                            <span>1)</span> <span className={'sub-heading'}>“Agreement”</span> - where you are
                            established in the European Economic Area, means (i) prior to 25th May 2018, the Directive
                            95/46/EC on the protection of individuals with regard to the Processing of Personal Data and
                            on the free movement of such data and (ii) after 25th May, 2018, the EU Regulation 2016/679
                            (General Data Protection Regulation) and any applicable national laws made under it; and
                            where you are established in any other territory, means data protection laws that are
                            applicable in that territory.
                        </p>

                        <p>
                            <span>2)</span> <span className={'sub-heading'}>“Applicable Data Protection Law”</span> -
                            means the agreement between Springworks and the customer for providing Software as a service
                            to the customer, incorporating these terms and conditions (including the Schedules and
                            Addendums) and the Statement of Services, and any amendments to the agreement from time to
                            time;
                        </p>

                        <p>
                            <span>3)</span> <span className={'sub-heading'}>“Authorised User”</span> means the user(s)
                            permitted to use the SaaS Services and the content, including Data, generated by or the
                            output of, the SaaS Services as a part of the customer’s services to its customers.
                        </p>

                        <p>
                            <span>4)</span> <span className={'sub-heading'}>“Charges”</span> means the user(s) permitted
                            to use the SaaS Services and the content, including Data, generated by or the output of, the
                            SaaS Services as a part of the customer’s services to its customers.
                        </p>

                        <p>
                            <span>5)</span> <span className={'sub-heading'}> “Customer”</span> means the meaning
                            accorded to it under Clause 1 of Acceptance.
                        </p>

                        <p>
                            <span>6)</span> <span className={'sub-heading'}>“Confidential Information” </span>” includes
                            information or documentation, in any form, which:
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span>is disclosed to the recipient in connection with this agreement (whether
                                before or after the Effective Date);
                            </p>
                            <p>
                                <span>b)</span>is prepared or produced under or in connection with this agreement
                                (whether before or after the Effective Date); or
                            </p>
                            <p>
                                <span>c)</span>relates to:
                            </p>

                            <div className='tnc-wrap-content-roman'>
                                <p>
                                    <span>i</span>the business, assets or affairs of Springworks or any of its
                                    affiliates;
                                </p>
                                <p>
                                    <span>ii.</span>the subject matter of, the terms of and/or any transactions
                                    contemplated under this agreement, whether or not such information or documentation
                                    is reduced to a tangible form or marked in writing as “confidential”, and whether it
                                    is disclosed to the recipient or received, acquired, overheard or learnt by the
                                    recipient in any way whatsoever.
                                </p>
                            </div>

                            <p>
                                <span>d)</span>Notwithstanding anything contained under this agreement, Confidential
                                Information shall not include any information which
                            </p>

                            <div className='tnc-wrap-content-roman'>
                                <p>
                                    <span>1</span>was publicly known and made generally available in the public domain
                                    prior to the time of disclosure by the disclosing party;
                                </p>
                                <p>
                                    <span>2</span>is already in the possession of the receiving party at the time of
                                    disclosure by the disclosing party as shown by the receiving party’s files and
                                    records prior to the time of disclosure;
                                </p>
                            </div>

                            <div className='tnc-wrap-content-roman-inner'>
                                <p>
                                    <span>i)</span>is obtained by the receiving party from a third party without a
                                    breach of such third party’s obligations of confidentiality;
                                </p>
                                <p>
                                    <span>ii)</span> is independently developed by the receiving party without use of or
                                    reference to the disclosing party’s Confidential Information, as shown by documents
                                    and other competent evidence in the receiving party’s possession; or
                                </p>
                                <p>
                                    <span>iii)</span>is required by law or under the directions of any court/ authority
                                    to be disclosed by the receiving party, provided that the receiving party shall, to
                                    the extent legally permitted, give the disclosing party written notice of such
                                    requirement prior to disclosing so that the disclosing party may seek a protective
                                    order or other appropriate relief.
                                </p>
                            </div>

                            <p>
                                <span>7)</span> <span className={'sub-heading'}>“Data”</span> means all of the
                                information, documents and other data provided by the customer or their personnel to
                                Springworks, any content uploaded by the customer or personnel to Springworks’s System,
                                or otherwise accessed by Springworks in providing the Services.
                            </p>

                            <p>
                                <span>8)</span> <span className={'sub-heading'}>“Effective Date”</span>means the date
                                that the agreement comes into force as specified in the beginning of the agreement;
                            </p>

                            <p>
                                <span>9)</span> <span className={'sub-heading'}>“Intellectual Property Rights”</span>{' '}
                                means all intellectual property rights wherever in the world, whether registered or
                                unregistered, including any application or right of application for such rights. It also
                                include copyright and related rights, database rights, confidential information, trade
                                secrets, know-how, business names, trade names, trademarks, service marks, passing off
                                rights, unfair competition rights, patents, petty patents, utility models,
                                semi-conductor topography rights and rights in designs
                            </p>

                            <p>
                                <span>10)</span> <span className={'sub-heading'}>“Schedule”</span> means all
                                intellectual property rights wherever in the world, whether registered or unregistered,
                                including any application or right of application for such rights. It also include
                                copyright and related rights, database rights, confidential information, trade secrets,
                                know-how, business names, trade names, trademarks, service marks, passing off rights,
                                unfair competition rights, patents, petty patents, utility models, semi-conductor
                                topography rights and rights in designs
                            </p>

                            <p>
                                <span>11)</span> <span className={'sub-heading'}>“Services” </span> means all the
                                services provided or to be provided by Springworks to the customer under the agreement,
                                including the Support Services.
                            </p>

                            <p>
                                <span>12)</span> <span className={'sub-heading'}>"Support Services"</span> means support
                                and maintenance services provided or to be provided by the provider to the customer.
                            </p>

                            <p>
                                <span>13)</span> <span className={'sub-heading'}>“Upgrades”</span> means new versions
                                of, and updates to the Services incorporated from time to time, whether for the purpose
                                of fixing an error, bug or other issue in the Services or for enhancing the
                                functionality of the Services.
                            </p>
                        </div>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>3.</span> INTERPRETATION
                        </h3>
                        <p>
                            <span>1.</span> In the agreement, a reference to a statute or statutory provision includes a
                            reference to:
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> that statute or statutory provision as amended, consolidated and/or
                                reenacted from time to time; and
                            </p>
                            <p>
                                <span>b)</span> any legislation or law includes subordinate legislation or law and all
                                amendments, consolidations, replacements or re-enactments from time to time.
                            </p>
                        </div>

                        <p>
                            <span>2.</span> The Clause headings as provided do not affect the interpretation of the
                            agreement.
                        </p>

                        <p>
                            <span>3.</span> There is no intention of using the ejusdem generis rule for the
                            interpretation of the agreement.
                        </p>

                        <p>
                            <span>4.</span> In this agreement, unless the context otherwise requires:
                        </p>
                        <div className='tnc-content-inner'>
                            <p>
                                <span>a)</span> the singular includes the plural and vice versa;
                            </p>

                            <p>
                                <span>b)</span> a reference to a clause, paragraph, schedule or annexure is a reference
                                to a clause, paragraph, schedule or annexure, as the case may be, of this agreement;
                            </p>

                            <p>
                                <span>c)</span> where a word or phrase is defined, its other grammatical forms have a
                                corresponding meaning;
                            </p>

                            <p>
                                <span>d)</span> a reference to a natural person includes a body corporate, partnership,
                                joint venture, association, government or statutory body or authority or other legal
                                entity and vice versa;
                            </p>

                            <p>
                                <span>e)</span> ‘includes’ and similar words mean includes without limitation;
                            </p>

                            <p>
                                <span>e)</span> ‘includes’ and similar words mean includes without limitation;
                            </p>

                            <p>
                                <span>f)</span> no clause will be interpreted to the disadvantage of a Party merely
                                because that Party drafted the clause or would otherwise benefit from it;
                            </p>

                            <p>
                                <span>g)</span> a reference to a party to a document includes that party’s executors,
                                administrators, successors, permitted assigns and persons substituted by from from time
                                to time;
                            </p>

                            <p>
                                <span>h)</span> a reference to this agreement or any other document includes the
                                document, all schedules, all annexures and Addendums as upgraded, novated, amended,
                                supplemented, varied or replaced from time to time.
                            </p>
                        </div>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>4.</span> THE PLATFORM
                        </h3>
                        <p>
                            <span>1.</span> As on the Effective date, Springworks will make available a Platform to the
                            customer by way of creating an account for the customer through which the customer can avail
                            the Services by signing in through the customer login details for that account.
                        </p>
                        <p>
                            <span>2.</span> Subject to the limitations as set out under this agreement, Springworks
                            hereby grants to the customer a non-exclusive license to use the Platform for the Permitted
                            Purpose of availing Services via any standard web browser/ application.
                        </p>
                        <p>
                            <span>3.</span> The license granted by Springworks to the customer under Clause 2 is subject
                            to the following limitations:
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> the Platform may only be used by the licensed user to whom Springworks
                                will provide customer login details, however, the customer may change the login details
                                under the prescribed procedure;
                            </p>
                            <p>
                                <span>b)</span> the Platform can only be used by one customer/ licenced group of
                                customers in accordance with the prescribed procedure from time to time in this regard;
                            </p>
                            <p>
                                <span>c)</span> the Platform may only be used by the employees, agents and
                                subcontractors of the customer and:
                            </p>

                            <div className='tnc-wrap-content-roman'>
                                <p>
                                    <span>i.</span> where the customer is a company, the customer's officers;
                                </p>
                                <p>
                                    <span>ii.</span> where the customer is a partnership, the customer's partners; and
                                </p>
                                <p>
                                    <span>iii.</span> where the customer is a limited liability partnership, the
                                    customer's members;
                                </p>
                            </div>

                            <p>
                                <span>d)</span> the customer must comply at all times with the terms as set out under
                                this agreement and under any Policy/ Addendum attached thereto.
                            </p>
                        </div>
                        <p>
                            <span>4.</span> Except to the extent mandated by applicable law or expressly permitted in
                            the agreement, the license granted by Springworks to the customer under this Clause is
                            subject to the following prohibitions:
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> the customer must not sub-license its right to access and use the
                                Platform or allow any unauthorized person to access or use the Platform;
                            </p>
                            <p>
                                <span>b)</span> the customer must not frame or otherwise re-publish or re-distribute the
                                Platform; and
                            </p>
                            <p>
                                <span>c)</span> the customer must not alter or adapt or edit the Platform save as
                                expressly permitted by Springworks.
                            </p>
                        </div>
                        <p>
                            <span>5.</span> For the avoidance of doubt, the customer has no right to access the object
                            code or source code of the Platform, either during or after the Term.
                        </p>
                        <p>
                            <span>6.</span> All Intellectual Property Rights in the Platform shall, as between the
                            parties, be the exclusive property of Springworks.
                        </p>
                        <p>
                            <span>7.</span> The customer shall use all reasonable endeavors to ensure that no
                            unauthorized person will or could access the Platform using the customer's account.
                        </p>
                        <p>
                            <span>8.</span> The customer must not use the Platform in any way that causes, or may cause,
                            damage to the Platform or impairment of the availability or accessibility of the Platform,
                            or any of the areas of, or services on, the Platform.
                        </p>
                        <p>
                            <span>9.</span> The customer must not use the Platform:
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> the customer must not sub-license its right to access and use the
                                Platform or allow any unauthorized person to access or use the Platform;
                            </p>
                        </div>
                        <p>
                            <span>10.</span> In case the customer’s login details are misplaced or are exposed to any
                            unauthorized person, then the customer shall immediately inform about such loss/ leaking of
                            details to Springworks.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>5.</span> SERVICES
                        </h3>
                        <p>
                            <span>1.</span> From the Effective Date and during the Term, Springworks agrees to provide
                            the Services in accordance with the terms of this agreement.
                        </p>
                        <p>
                            <span>2.</span> Springworks reserves the right to change or remove features of the SaaS
                            Services from time to time. Where there is any material alteration to the SaaS Services in
                            accordance with this clause, Springworks will provide the customer with a notice of twenty
                            (20) days and the customer agrees that any material alteration is at Springworks’s
                            discretion.
                        </p>
                        <p>
                            <span>3.</span> The Parties agree that the Springworks:
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> will supply the Services on a non-exclusive basis;
                            </p>
                            <p>
                                <span>b)</span> the services provided under this agreement are not defect free and
                                Springworks does not take responsibility for any loss to the customer in use of the
                                Services; and
                            </p>
                            <p>
                                <span>c)</span> does not make any warranty or representation as to the ability of the
                                facilities or services of any third-party suppliers; and
                            </p>
                            <p>
                                <span>d)</span> is not liable for any failure in, fault with or degradation of the
                                Services if that failure, fault or degradation is attributable to or caused by any
                                failure of the customer or the facilities or services of any third party.
                            </p>
                        </div>
                        <p>
                            <span>4.</span> Springworks reserves the right to refuse any request in relation to the
                            Services that it deems inappropriate, unreasonable or illegal.
                        </p>
                        <p>
                            <span>5.</span> customer’s use of the Services- You agree NOT to:
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> license, sublicense, sell, resell, rent, lease, transfer, assign,
                                distribute, time share or otherwise commercially exploit or make the Services available
                                to any third party, other than Users and End-Users in furtherance of Your internal
                                business purposes as expressly permitted by these Terms;
                            </p>
                            <p>
                                <span>b)</span> use the Services to Process data on behalf of any third party other than
                                Your Users and End-Users;
                            </p>
                            <p>
                                <span>c)</span> modify, adapt, or hack the Services or otherwise attempt to gain or gain
                                unauthorized access to the Services or related systems or networks;
                            </p>
                            <p>
                                <span>d)</span> falsely imply any sponsorship or association with Us;
                            </p>
                            <p>
                                <span>e)</span> use the Services in any unlawful manner, including but not limited to
                                violation of any person’s privacy rights;
                            </p>
                            <p>
                                <span>f)</span> use the Services to send unsolicited communications junk mail, spam,
                                pyramid schemes or other forms of duplicative or unsolicited messages;
                            </p>
                            <p>
                                <span>g)</span> use the Services to store or transmit any content that infringes upon
                                any person’s intellectual property rights;
                            </p>
                            <p>
                                <span>h)</span> use the Services in any manner that interferes with or disrupts the
                                integrity or performance of the Services and its components;
                            </p>
                            <p>
                                <span>i)</span> attempt to decipher, decompile, reverse engineer, disassemble,
                                reproduce, or copy or otherwise access or discover the source code or underlying program
                                of any Software making up the Services;
                            </p>
                            <p>
                                <span>j)</span> use the Services to knowingly post, transmit, upload, link to, send or
                                store any content that is unlawful, racist, hateful, abusive, libelous, obscene, or
                                discriminatory;
                            </p>
                            <p>
                                <span>k)</span> use the Services to knowingly post, transmit, upload, link to, send or
                                store any viruses, malware, Trojan horses, time bombs, or any other similar harmful
                                software;
                            </p>
                            <p>
                                <span>i)</span> use the Services to knowingly post, transmit, upload, link to, send or
                                store any viruses, malware, Trojan horses, time bombs, or any other similar harmful
                                software;
                            </p>
                            <p>
                                <span>l)</span> establish a link to Our Websites in such a way as to suggest any form of
                                association, approval or endorsement on Our part where none exists;
                            </p>
                            <p>
                                <span>m)</span> use the Services for the purposes of cookie tracking, ad exchanges, ad
                                networks, data brokerages, or sending electronic communications (including e-mail) in
                                violation of applicable law;
                            </p>
                            <p>
                                <span>n)</span> use of the Services for any purpose prohibited by applicable export laws
                                and regulations, including without limitation, nuclear, chemical, or biological weapons
                                proliferation, or development of missile technology;
                            </p>
                            <p>
                                <span>0)</span> try to use, or use the Services in violation of these Terms.
                            </p>
                        </div>
                        <p>
                            <span>6. </span> The customer shall assume all the responsibility for any loss of data or
                            attempted or actual access or use of the Services through the customer’s Account in
                            violation of these Terms.
                        </p>
                        <p>
                            <span>7. </span> If We inform You or your personnel that a specified activity or purpose is
                            prohibited with respect to the Service, You will ensure that You immediately cease use of
                            the Service for such prohibited activity or purpose.
                        </p>
                        <p>
                            <span>8. </span> Access to Services
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> You may not be able to access or use the Services:
                            </p>
                            <div className='tnc-wrap-content-roman'>
                                <p>
                                    <span>i.</span> during planned downtime for upgrades and maintenance to the Service
                                    which shall be reasonably notified to you in advance as far as possible, or
                                </p>
                                <p>
                                    <span>ii.</span> during any unavailability caused by circumstances beyond Our
                                    reasonable control, such as, but not limited to, acts of God, acts of government,
                                    acts of terror or civil unrest,technical failures beyond Our reasonable control
                                    (including, without limitation, inability to access the internet), or acts
                                    undertaken by third parties, including without limitation, distributed denial of
                                    service attacks.
                                </p>
                            </div>
                            <p>
                                <span>b)</span> As far as possible We will use commercially reasonable efforts to
                                schedule the maintenance to Services during weekends and other off-peak hours.
                            </p>
                        </div>

                        <p>
                            <span>9. </span> Support Services and Upgrades - During the Term of the Services,
                            Springworks will provide the Support Services to the customer, and may apply Upgrades to the
                            Services/Platform, in accordance with the agreement. The customer agrees that We may, at any
                            time, sub-contract the provision of any of the Support Services, without obtaining the
                            consent of the customer.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>6.</span> CUSTOMER RESPONSIBILITIES AND OBLIGATIONS
                        </h3>
                        <p>
                            <span>1.</span> The customer will provide all the required data and materials as required by
                            Springworks from time to time for Springworks to perform the Services.
                        </p>
                        <p>
                            <span>2.</span> At the expense/ risk of the customer, you must:
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> You may not be able to access or use the Services:
                            </p>
                            <p>
                                <span>b)</span> make all the reasonable efforts on his part to ensure that the integrity
                                of the Data is maintained;
                            </p>
                            <p>
                                <span>c)</span> permit Springworks and its personnel/ vendors/ affiliates/
                                representatives to have reasonable access and permission to process the data/
                                information provided by the customer in order to make possible, the Services to be
                                provided by Springworks under this agreement;
                            </p>
                            <p>
                                <span>d)</span> ensure that the Services provided to you under this agreement are
                                exclusive to you only and not to any unauthorized user.
                            </p>
                        </div>

                        <p>
                            <span>3.</span> The customer shall have the sole responsibility as regards the use of the
                            Services provided under this agreement and that the customer shall ensure the Services are
                            NOT used:
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> to break any Applicable Law or infringe any person’s rights including
                                but not limited to Intellectual Property Rights;
                            </p>
                            <p>
                                <span>b)</span> to transmit, publish or communicate material that is defamatory,
                                offensive, abusive, indecent, menacing or unwanted; or
                            </p>
                            <p>
                                <span>c)</span> in any way that damages, interferes with or interrupts the supply of the
                                Services.
                            </p>
                        </div>

                        <p>
                            <span>4.</span> The customer acknowledges and agrees that:
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> it shall be the responsibility of the customer as regards the usage of
                                Services by any/all of the end users, including its personnel and any authorized users;
                            </p>
                            <p>
                                <span>b)</span> the use of the Services will be at the risk of the customer;
                            </p>
                            <p>
                                <span>c)</span> it is the responsibility of the customer to maintain the security of its
                                account and password. Springworks cannot and will not be liable for any loss or damage
                                from the customer’s failure to comply with this security obligation;
                            </p>
                            <p>
                                <span>d)</span> Springworks may alter or update the customer’s account logins and
                                passwords and the logins and passwords of any Authorised Users at any time throughout
                                the Term;
                            </p>
                            <p>
                                <span>e)</span> the customer shall take the sole responsibility of all the content
                                posted and activity that occurs under their account. This includes content posted by
                                others who have logins or accounts associated with the customer’s account;
                            </p>
                            <p>
                                <span>f)</span> the customer will provide all the co-operation and reasonable assistance
                                to Springworks for and Upgrades to the Services including but not limited to
                                reconfigurations or interface customizations; and
                            </p>
                            <p>
                                <span>g)</span> the Springworks shall be at liberty to initiate and available equitable
                                or other remedy against the customer as a result of a breach by the customer or its
                                authorized users, of any provision of this agreement.
                            </p>
                        </div>

                        <p>
                            <span>5.</span> Springworks has the right (but not the obligation) to suspend access to any
                            of the Services provided under this agreement without liability :
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> if Springworks reasonably believes that the Services are being used in
                                violation of the terms of this agreement or against any applicable statute,
                            </p>
                            <p>
                                <span>b)</span> in compliance of any order passed by any governing authority or under
                                the orders of the court.
                            </p>
                        </div>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>7.</span> BILLING, PLAN MODIFICATIONS AND PAYMENTS
                        </h3>
                        <p>
                            <span>1.</span> <span className={'sub-heading'}>Subscription Charges:</span> Unless
                            otherwise specified except during your free trial all charges associated with Your Account
                            (“Subscription Charges”) are due in full and payable in advance when You subscribe to the
                            Service(s). Unless specified otherwise in a Form, the Subscription Charges are based on the
                            Service Plans You choose and are payable in full until You terminate Your Account. You will
                            receive a receipt upon each receipt of payment by Us. You may als obtain a payment receipt
                            from within the Service(s).
                        </p>
                        <p>
                            <span>2.</span> <span className={'sub-heading'}>Payment methods: </span> You may pay the
                            Subscription Charges through Your credit card, or other accepted payment method as specified
                            in a Form. For credit card payments, Your payment is due immediately upon Your receipt of
                            Our invoice. You hereby authorize Us or Our authorized agents, as applicable, to bill Your
                            credit card upon Your subscription to the Service(s) (and any renewal thereof). For payments
                            through other accepted methods, Your payment is due within seven (7) days of our invoice
                            date unless otherwise stated in a Form.
                        </p>
                        <p>
                            <span>3.</span> <span className={'sub-heading'}>Renewal: </span> Your subscription to the
                            Service(s) will renew automatically for a Subscription Term equivalent in length to the then
                            expiring Subscription Term. Unless otherwise provided for in any Form, the Subscription
                            Charges applicable to Your subscription to the Service(s) for any such subsequent
                            Subscription Term shall be Our standard Subscription Charges for the Service Plan to which
                            You have subscribed as of the time such subsequent Subscription Term commences. You
                            acknowledge and agree that, unless You terminate Your Account Your credit card will be
                            charged automatically for the applicable Subscription Charges.
                        </p>
                        <p>
                            We may use a third party service provider to manage credit card and other payment
                            processing; provided that such service provider is not permitted to store, retain or use
                            Your payment account information except to process Your credit card and other payment
                            information for Us. You must notify Us of any change in Your credit card or other payment
                            account information, either by updating Your Account or by emailing Us at{' '}
                            <a href='mailto:support@springworks.in'> support@springworks.in</a>
                        </p>
                        <p>
                            <span>4.</span> <span className={'sub-heading'}>Refunds: </span> Unless otherwise specified
                            in these Terms or a Form or a Service Plan, all Subscription Charges are nonrefundable. No
                            refunds shall be issued for partial use or non-use of the Service(s) by You provided however
                            You shall be eligible for a pro-rated refund of the Subscription Charges for the remainder
                            of the Subscription Term if You terminate Your Account as a result of a material breach of
                            these Terms by Us.
                        </p>
                        <p>
                            <span>5.</span>{' '}
                            <span className={'sub-heading'}>Late Payments/Non-payment of Subscription Charges: </span>{' '}
                            We will notify You if We do not receive payment towards the Subscription Charges within the
                            due date for Your Account. For payments made through credit cards, We must receive payments
                            due within a maximum of five (5) days from the date of Our notice. For payments through
                            other accepted methods, We must receive payments within a maximum of seven (7) days from the
                            date of Our notice. If We do not receive under prevailing law, We may (i) charge an interest
                            for late payment @ 1.5% per month and/or; (ii) suspend Your access to and use of the
                            Service(s) until We receive Your payment towards the Subscription Charges as specified
                            herein and/or; (iii) terminate Your Account.
                        </p>
                        <p>
                            <span>6.</span> <span className={'sub-heading'}>User Benefits: </span> We may, at Our sole
                            discretion, offer You certain benefits such as discounts on Subscription Charges, extension
                            in Subscription Term for no extra payments from You, with regard to the Service(s). These
                            benefits are specific to Your Account and the Service(s) identified while offering these
                            benefits. They are not transferable. The benefits may have an expiry date. If they do not
                            have an expiry date, they will expire upon completion of twelve (12) months from their date
                            of offer.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>8.</span> OWNERSHIP
                        </h3>
                        <p>
                            <span>1.</span> By agreeing to enter into this agreement the customer irrevocably
                            acknowledges that, subject to the licenses granted herein, the customer has no ownership
                            interest in the Services or the materials provided by Springworks to the customer.
                            Springworks holds all the rights, titles, and interests in the Services or materials
                            provided by it including but not limited to software, applications, login details and any
                            other material, subject to any limitations associated with intellectual property rights of
                            third parties. Springworks reserves all rights not specifically granted herein under this
                            clause.
                        </p>
                        <p>
                            <span>2.</span> This clause shall survive post the termination of this agreement.
                        </p>
                    </div>
                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>9.</span> INTELLECTUAL PROPERTY RIGHTS
                        </h3>
                        <p>
                            <span>1.</span> Ownership of IPR: Except for the rights granted to the You under this
                            agreement, all the title and interest in and to all Our patents, inventions, copyrights,
                            trademarks, domain names, trade secrets, know-how and any other intellectual property and/or
                            proprietary rights in or related to the Service(s), including the Websites, Applications and
                            any part of it (collectively, “Intellectual Property Rights”) shall belong to and remain
                            exclusively with Us. We are the owner or the licensee of all Intellectual Property Rights in
                            Our Websites, and the content or material published on it. Those works are protected by
                            copyright laws and treaties around the world. You must not use any part of the content on
                            Our Websites for commercial purposes without obtaining a license to do so from Us or Our
                            licensors. Further, We claim no intellectual property rights over the content You upload or
                            provide to us for the purpose of availing services under this agreement.
                        </p>
                        <p>
                            <span>2.</span> Grant of License: The customer shall be prohibited to use Our product and
                            service names, and logos used or displayed on the Services or Websites, which are Our
                            registered or unregistere trademarks (collectively “Marks”), however, the customer may only
                            use such Marks to identify him as a user of the Services subscribed to under this agreement.
                        </p>
                        <p>
                            <span>3.</span> Reservation of Rights: All rights not expressly provided herein are reserved
                            with Us. We do not screen content uploaded onto the SaaS Service, but we have the right (but
                            not the obligation) in our sole discretion to refuse or remove any content that is available
                            via the Service that we deem otherwise objectionable or violate any party’s Intellectual
                            Property Rights or this agreement.
                        </p>
                        <p>
                            <span>4.</span> This clause will survive the termination or expiry of this agreement.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>10.</span> CONFIDENTIALITY
                        </h3>
                        <p>
                            <span>1.</span> Springworks will:
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> keep confidential and not disclose the customer’s Confidential
                                Information to any person save as expressly permitted by this Clause;
                            </p>
                            <p>
                                <span>b)</span> protect the customer’s Confidential Information against unauthorised
                                disclosure by using the same degree of care as it takes to preserve and safeguard its
                                own confidential information of a similar nature, being at least a reasonable degree of
                                care;
                            </p>
                        </div>

                        <p>
                            <span>2.</span> The customer’s Confidential Information may be disclosed only on ‘need to
                            know ‘ basis by the Springworks to its officers, employees, agents, insurers and
                            professional advisers, vendors, affiliates, provided that the recipient is bound in writing
                            to maintain the confidentiality of the customer’s Confidential Information so disclosed by
                            it.
                        </p>
                        <p>
                            <span>3.</span> The provisions as enumerated under this Clause shall supersede any
                            non-disclosure agreement entered into between the Parties to this agreement, which may be
                            entered prior to these Terms that would purport to address the confidentiality of customer’s
                            Data and such agreement shall have no further force or effect with respect to customer’s
                            Confidential Data.
                        </p>
                        <p>
                            <span>4.</span> Exceptions. Without granting any right or license, the obligations of the
                            parties hereunder will not apply to any material or information that is provided as an
                            exception under the definition of Confidential Information.
                        </p>
                        <p>
                            <span>5.</span> This clause will survive post the termination or expiry of this agreement.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>11.</span> DATA PRIVACY AND SECURITY
                        </h3>
                        <p>
                            <span>1.</span>Security of customer’s Data: Springworks undertakes to use appropriate
                            technical and organizational measures to protect the Data provided by the customer. The
                            measures so used are designed to provide a level of security appropriate to the risk of
                            processing the customer’s Data.
                        </p>
                        <p>
                            <span>2.</span>The customer acknowledges and agrees that Springworks may access or disclose
                            information about the customer, Account, Users or End-Users, including customer’s Data, in
                            order to:
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> if Springworks reasonably believes that the Services are being used in
                                violation of the terms of this agreement or against any applicable statute,
                            </p>
                            <p>
                                <span>b)</span> protect Springworks’s rights or property, including enforcement of the
                                terms of this agreement or other policies associated with the Services provided under
                                this agreement;
                            </p>
                            <p>
                                <span>c)</span> act on a good faith and belief that such disclosure is necessary to
                                protect personal safety or avoid violation of applicable statutes/ law/ rules or
                                regulations.
                            </p>
                        </div>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>12.</span>GDPR SUPPORT
                        </h3>
                        <p>
                            <span>1.</span>Springworks products provide the convenience of enforcing your
                            personal/company’s defined limitations/policies through the product itself. Eg: Assistance
                            with restriction of use of data by turning certain product features ON/OFF. For similar
                            requests reach out to <a href='mailto:support@springworks.in'>support@springworks.in</a>
                        </p>
                        <p>
                            <span>2.</span>Our products directly assist in you/your customer’s need to meet ‘right to
                            portability’ requests. All the collected data can be exported from the product by users who
                            have appropriate access rights. For additional requests on obtaining existing data, reach
                            out to <a href='mailto:support@springworks.in'>support@springworks.in</a>
                        </p>
                        <p>
                            <span>3.</span>Our products offer flexibility of controlling access/permissions, thus
                            enabling unauthorised access and protection of sensitive data. Springworks will help
                            you/your customer’s request for permanent erasure of an individual’s data from within the
                            product. For similar requests reach out to{' '}
                            <a href='mailto:support@springworks.in'>support@springworks.in</a>
                        </p>
                        <p>
                            <span>4.</span>Springworks regularly evaluates enforcement of - security policies,
                            utilization of dynamic access controls, identity verification of those accessing data, and
                            implementation of protection mechanisms against data breach.
                        </p>
                        <p>
                            <span>5.</span>Springworks products help maintain an audit trail to enable you to provide
                            evidence of appropriate actions taken on an individual’s request.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>13.</span>WARRANTIES
                        </h3>
                        <p>
                            <span>1.</span>Mutual Warranties
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span> The Parties have all the requisite powers and authority to enter into
                                this agreement and have taken all actions necessary to execute, deliver, and perform
                                their obligations under this agreement.
                            </p>
                            <p>
                                <span>b)</span> This agreement has been validly executed by the Parties and the same
                                constitutes a valid agreement which is binding on both the Parties and enforceable in
                                accordance with the prevailing law.
                            </p>
                            <p>
                                <span>c)</span> All the information communicated by either of the Parties to each other
                                for the purpose of this agreement is true, correct and complete and is not misleading in
                                letter or in spirit whether by reason of omission to state a material fact or otherwise.
                            </p>
                            <p>
                                <span>d)</span> Neither the making or performance of this agreement, nor the compliance
                                with its terms will be in violation of any Law or in conflict with or result in the
                                breach of or constitute a default or require any consent under any decree, order, or any
                                other instrument to which either Party is a party or by which either Party is bound and
                                no such decree, order, judgment, execution, claim, settlement or other instrument exists
                                against it.
                            </p>
                        </div>
                        <p>
                            <span>2.</span>DISCLAIMER OF WARRANTY
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span>ANY AND ALL OF SERVICES OR CONFIDENTIAL INFORMATION AND ANY OTHER
                                TECHNOLOGY OR MATERIALS PROVIDED BY Springworks TO THE CUSTOMER ARE PROVIDED ON “AS IS”
                                BASIS AND WITHOUT WARRANTY OF ANY KIND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
                                LAW. Springworks MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING EXPRESS OR
                                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                                NON-INFRINGEMENT. NEITHER Springworks NOR ANY OF ITS SUBSIDIARIES, AFFILIATES,
                                SUPPLIERS, LICENSORS, VENDORS, REPRESENTATIVES, OR AFFILIATES WARRANTS OR REPRESENTS
                                THAT THE SERVICES PROVIDED UNDER THIS AGREEMENT WILL BE UNINTERRUPTED, ERROR-FREE, OR
                                SECURE. CUSTOMER ACKNOWLEDGES THAT THERE ARE RISKS INHERENT IN INTERNET CONNECTIVITY
                                THAT COULD RESULT IN THE LOSS OF CUSTOMER’S PRIVACY, DATA, CONFIDENTIAL INFORMATION, AND
                                PROPERTY.
                            </p>
                            <p>
                                <span>b)</span>THE CONTENT ON OUR WEBSITES IS PROVIDED FOR GENERAL INFORMATION ONLY. IT
                                IS NOT INTENDED TO AMOUNT TO ADVICE ON WHICH YOU SHOULD RELY. YOU MUST OBTAIN
                                PROFESSIONAL OR SPECIALIST ADVICE BEFORE TAKING, OR REFRAINING FROM, ANY ACTION ON THE
                                BASIS OF THE CONTENT ON OUR WEBSITES. NO INFORMATION OR ADVICE OBTAINED BY YOU FROM US
                                OR THROUGH THE SERVICES OR WEBSITES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
                                THESE TERMS.
                            </p>
                        </div>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>14.</span>INDEMNITY, LIMITATIONS AND EXCLUSIONS OF LIABILITY
                        </h3>
                        <p>
                            <span>1.</span>EXCEPT FOR THE INDEMNIFICATION PROVIDED UNDER THIS CLAUSE, OUR MAXIMUM
                            LIABILITY FOR ANY ACTION ARISING UNDER THESE TERMS AND CONDITIONS, REGARDLESS OF THE FORM OF
                            ACTION AND WHETHER IN TORT, CONTRACT OR OTHER FORM OF LIABILITY, SHALL IN NO EVENT EXCEED
                            THE FEES PAID BY THE CUSTOMER DURING THE TERM OF THIS AGREEMENT. IN NO EVENT SHALL
                            Springworks BE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY
                            KIND, INCLUDING WITHOUT LIMITATION, LOST DATA, LOST PROFITS, OPPORTUNITIES OR CONTRIBUTIONS,
                            LOSS OF USE, GOOD WILL, BUSINESS INTERRUPTION, COST OF COVER, OR OTHER PECUNIARY OR
                            NON-PECUNIARY LOSS, HOWEVER ARISING, EVEN IF SPRINGWORKS HAS BEEN ADVISED OF THE POSSIBILITY
                            OF SUCH DAMAGES.
                        </p>
                        <p>
                            <span>2.</span>SPRINGWORKS SHALL HAVE NO LIABILITY OR RESPONSIBILITY IN THE EVENT OF ANY
                            LOSS OR INTERRUPTION IN ACCESS TO THE SERVICES PROVIDED BY Springworks UNDER THIS AGREEMENT,
                            DUE TO CAUSES BEYOND ITS REASONABLE CONTROL OR FORESEEABILITY, SUCH AS LOSS, INTERRUPTION OR
                            FAILURE OF TELECOMMUNICATIONS OR DIGITAL TRANSMISSIONS AND LINKS, INTERNET SLOWDOWN OR
                            FAILURES. THE PARTIES AGREE TO THE ALLOCATION OF RISK SET FORTH HEREIN.
                        </p>
                        <p>
                            <span>3.</span>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SPRINGWORKS’S AGGREGATE
                            LIABILITY AND THAT OF OUR AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS,
                            REPRESENTATIVES, VENDORS AND LICENSORS, RELATING TO THE SERVICES PROVIDED UNDER THIS
                            AGREEMENT, WILL NOT EXCEED AN AMOUNT EQUAL TO THE LOWER OF THE SUBSCRIPTION CHARGES PAID BY
                            THE CLIENT, FOR THE SERVICES TO WHICH THE CLAIM RELATES, PRIOR TO THE FIRST EVENT OR
                            OCCURRENCE GIVING RISE TO SUCH LIABILITY.
                        </p>
                        <p>
                            <span>4.</span>Indemnity By Springworks :
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span>Springworks shall indemnify and defend customer against any third party
                                claims where the services provided under this agreement by Springworks infringe any
                                copyright during the Term of this agreement, provided that Springworks is given prompt
                                notice of such claim and is given information, reasonable assistance, and the sole
                                authority to defend or settle said claim.
                            </p>
                            <p>
                                <span>b)</span>In the defense or settlement of any claim relating to infringement
                                arising out of services under this agreement, Springworks shall, in its reasonable
                                judgment and at its option and expense:
                            </p>
                            <div className='tnc-wrap-content-roman'>
                                <p>
                                    <span>i.</span>Shall provide the customer with the right to continue using the
                                    services;
                                </p>
                                <p>
                                    <span>ii.</span>replace or modify the services under this agreement so that they
                                    become non-infringing while giving substantially equivalent functionality; or
                                </p>
                                <p>
                                    <span>iii.</span>if Springworks determines that the remedies in (i) or (ii) are not
                                    commercially reasonable, at its sole obligation, terminate the term of this
                                    agreement.
                                </p>
                            </div>

                            <p>
                                <span>b)</span>Springworks shall have no liability to indemnify and defend the customer
                                to the extent
                            </p>

                            <div className='tnc-wrap-content-roman'>
                                <p>
                                    <span>i.</span>the alleged infringement is based on infringing information, data,
                                    software, applications, services, or programs created or furnished by or on behalf
                                    of the customer;
                                </p>
                                <p>
                                    <span>ii.</span>the alleged infringement is the result of a modification made by
                                    anyone other than Springworks; or
                                </p>
                                <p>
                                    <span>iii.</span>customer uses the services other than in accordance with these
                                    Terms and Conditions or any documentation delivered by Springworks. This clause
                                    incorporates Springworks’s entire liability and Client’s sole and exclusive remedy
                                    for claims relating to infringement.
                                </p>
                            </div>

                            <p>
                                <span>5.</span>Indemnity by the customer: The customer agrees to indemnify, and hold
                                harmless, Springworks against all Liabilities and Claims arising out of or in connection
                                with:
                            </p>
                            <div className='tnc-wrap-content-roman'>
                                <p>
                                    <span>a)</span>any and all unauthorized use of the Services provided under this
                                    agreement;
                                </p>
                                <p>
                                    <span>b)</span>authorized Users access or use of the Services;
                                </p>
                                <p>
                                    <span>c)</span>breach of any of the customer Obligations as set out in this
                                    agreement;
                                </p>
                                <p>
                                    <span>d)</span>customer’s gross negligence or willful misconduct;
                                </p>
                                <p>
                                    <span>e)</span>actual or alleged use of the services in violation of the terms of
                                    this agreement or against applicable law/ statute/ order/ directions/ rules/
                                    regulations, by the customer or any of its Authorized Users
                                </p>
                            </div>

                            <p>
                                <span>6.</span>This clause shall survive post the termination or expiry of this
                                agreement.
                            </p>
                        </div>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>15.</span>SUSPENSION AND TERMINATION
                        </h3>
                        <p>
                            <span>1.</span>This agreement shall continue to be in force till the period of subscription,
                            as availed by the customer.
                        </p>
                        <p>
                            <span>2.</span>Springworks may suspend the customer’s account upon expiry or non-renewal of
                            the subscription term, as availed by the customer. In such an event, Springworks shall
                            retain any associated data for a period of 12 months beyond which the customer’s account
                            shall be terminated and all associated Service Data shall be deleted immediately and
                            permanently.
                        </p>
                        <p>
                            <span>3.</span>Springworks shall not be liable to the customer or its authorized users or
                            any other third party for suspension or termination of the customer’s Account, or access to
                            and use the Services, if such suspension or termination is in accordance with the terms of
                            this agreement.
                        </p>
                        <p>
                            <span>4.</span>Any free trial account of the customer shall stand suspended automatically
                            after the expiration free trial period, unless the subscription is renewed by the customer
                            before the date of expiry of the free trial period. Springworks shall retain any associated
                            data for a period of 12 months beyond which the customer’s account shall be terminated and
                            all the data shall be deleted permanently.
                        </p>
                        <p>
                            <span>5.</span>The customer may terminate the account at any time on or before the expiry of
                            free trial period. In such cases, all associated service data shall be deleted immediately
                            and permanently.
                        </p>
                        <p>
                            <span>6.</span>If the customer has availed any of the service plans as provided by
                            Springworks, then the customer’s account may be suspended or terminated in the following
                            manner:
                        </p>

                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>a)</span>In addition to suspension for late payment or non-payment of subscription
                                charges, Springworks may suspend the customer’s access to and use of the customer’s
                                account or the Services under this agreement, if the customer is found to be in
                                violation of the terms.
                            </p>
                            <p>
                                <span>b)</span>Springworks shall notify the customer of the activities that violate the
                                terms of this agreement, provided that the customer may be given a ‘cure period’ of 15
                                days, at the sole discretion of Springworks, to rectify the activities that violate
                                these terms.
                            </p>
                            <p>
                                <span>c)</span>If the customer fails to rectify such violative activities or that
                                Springworks believes that the breach committed on the part of the customer cannot be
                                rectified, then the customer’s account shall stand terminated. Any associated data shall
                                be retained for a period of 12 months from the date of termination of the customer’s
                                account, beyond which it shall be deleted during the normal course of operation.
                            </p>
                        </div>
                        <p>
                            <span>7.</span>Upon the termination of customer’s account/ services under this agreement; in
                            accordance with the afore-mentioned clauses, the customer shall be required to immediately
                            pay any subscription charges/ amounts that may be due to Springworks under this agreement,
                            unless waived by Springworks in writing.
                        </p>
                        <p>
                            <span>8.</span>In case the account of / or services to the customer are terminated as a
                            result of a material breach of the terms of this agreement on the part of Springworks, then
                            the customer may be eligible to receive prorated refund of the subscription charges, as the
                            case may be; provided that the customer is required to provide Springworks with a notice of
                            not less than 30 days, affording it the opportunity to rectify any such breach committed by
                            Springworks.
                        </p>
                        <p>
                            <span>9.</span>This clause shall survive post the termination or expiry of this agreement.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>16.</span>NOTICES
                        </h3>
                        <p>
                            All the notices or other communications referenced under the terms and Conditions of this
                            agreement shall be made in writing and sent to Springworks’s address available at 1447 2nd
                            Street, Santa Monica, California, CA 90401 or through electronic mail at
                            support@springworks.in, whatsoever the case may be. All notices shall be deemed given to the
                            other party if delivered receipt confirmed using one of the following methods, whichever is
                            available or most commercially viable with the sender: electronic mail, registered or
                            certified first class mail, postage prepaid; recognized courier delivery.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>17.</span>COMMUNICATIONS FROM Springworks
                        </h3>
                        <p>
                            Apart from the communications specified under this agreement, Springworks may contact the
                            customer directly via e-mail to notify if :
                        </p>
                        <div className='tnc-wrap-content-inner'>
                            <p>
                                <span>1.</span>The customer is acting in violation of these Terms and Conditions;
                                (update of T&C)
                            </p>
                            <p>
                                <span>2.</span>A specific activity or purpose is prohibited with respect to the
                                Service(s) provided under this agreement, so that the customer may immediately cease the
                                use of the Services for such prohibited activity or purpose.
                            </p>
                        </div>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>18.</span> FORCE MAJEURE
                        </h3>
                        <p>
                            Neither Party to this agreement shall be responsible for any delay in the performance of any
                            terms and conditions hereunder to the extent that such delay is caused by war, revolution,
                            riot, act of public enemy, terrorism, sabotage, epidemic, quarantine restrictions, accident,
                            fire flood, tempest, earthquake, civil commotion, governmental action, Acts of God (Force
                            Majeure) including, but not restricted to, strike, lock-out, fire, break-down, war,
                            destruction of network, web space or website (including failures of internet or any public
                            telecommunications network, hacker attacks, virus or other malicious software attacks, power
                            failure) and, or restriction of Government, inability to secure Government authorization, or
                            approval, or any other cause beyond their reasonable control.
                        </p>
                        <p>
                            If at any time during the term of this agreement the performance in whole or in part by any
                            one of the Parties, of any obligation under this agreement is prevented or delayed by
                            reasons mentioned above the other Party shall not be entitled to terminate this agreement
                            neither shall any Party have any claim for damages against the other in respect of such
                            non-performance or delay in performance, provided notice of the happening of any such
                            event/s is given by the affected Party to the other within thirty (30) days from the date of
                            occurrence thereof.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>19.</span> ENTIRE AGREEMENT
                        </h3>
                        <p>
                            This agreement including all annexures, attachments, addendums and schedules to this
                            agreement constitute the entire agreement pertaining to the subject matter hereof and
                            supersedes in their entirety all written or oral agreements between the Parties.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>20.</span> ASSIGNMENT
                        </h3>
                        <p>
                            The customer may not assign any right and obligations under these Terms and Conditions or
                            otherwise transfer any license created hereunder whether by operation of law, change of
                            control, or in any other manner, without the prior written consent of the Service provider.
                            Any purported assignment of these
                        </p>
                        <p>
                            Terms and Conditions, or any license or rights in violation of this Section will be deemed
                            void. The Service provider may assign, in whole or in part, its rights, interests, and
                            obligations hereunder without limitation
                        </p>
                        <p>
                            Any services provided to the customer under this agreement, through Springworks’s
                            affiliates/ vendors/ representatives/ contractors shall not constitute assignment for the
                            purposes of this clause.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>21.</span> SEVERANCE
                        </h3>
                        <p>
                            If any provision in these Terms and Conditions is held by a court of competent jurisdiction
                            to be unenforceable, such provision shall be modified by the court and interpreted so as to
                            best accomplish the original provision to the fullest extent permitted by Applicable Law,
                            and the remaining provisions of these Terms and Conditions shall remain in effect.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>22.</span> WAIVERS
                        </h3>
                        <p>
                            No waiver by either Party of any of their terms hereof or of any breach thereof shall
                            constitute or be deemed to be a waiver of any such terms or of any breach in any other case
                            whether prior or subsequent thereto.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>23.</span> REVISIONS
                        </h3>
                        <p>
                            Springworks may amend these Terms and Conditions from time to time, in which case the new
                            Terms and Conditions will supersede prior versions. The customer is required to check these
                            Terms and Conditions from time to time to take notice of any changes made hereunder, as the
                            same shall be binding upon the customer. Springworks will notify the customer not less than
                            seven (7) days prior to the effective date of any amendments to these Terms and Conditions
                            and the customer’s continued use of the Service(s) following the effective date of any such
                            amendment may be relied upon by Springworks as customer’s acceptance of any such amendment.
                            Springworks’s failure to enforce at any time any provision of these Terms and Conditions,
                            does not constitute a waiver of that provision or of any other provision of the Terms and
                            Conditions.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>24.</span> RELATIONSHIP OF PARTIES
                        </h3>
                        <p>
                            <span>1.</span>This agreement is not intended to create a partnership, joint venture or
                            agency relationship between the Parties.
                        </p>
                        <p>
                            <span>2.</span>Nothing in this agreement gives a Party authority to bind any other Party in
                            any way.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>25.</span> SUBCONTRACTING
                        </h3>
                        <p>
                            <span>1.</span>The customer understands and agree that Springworks may engage subcontractors
                            to perform the Services on its behalf
                        </p>

                        <p>
                            <span>a.</span>Springworks will have the right to use third parties, including, but not
                            limited to, employees of Springworks’s affiliates/ representatives/ vendors/ agents/
                            contractors in performance of its obligations and services hereunder and; for purposes of
                            these Terms and Conditions, all references to Springworks or its employees will be deemed to
                            include such affiliates/ representatives/ vendors/ agents/ contractors.
                        </p>
                    </div>

                    <div className='tnc-wrap-content'>
                        <h3>
                            <span>25.</span> GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION:
                        </h3>
                        <p>
                            <span>1.</span>This agreement shall be construed according to the laws of the State of
                            California, and any dispute or claim arising under this agreement shall be subject to the
                            exclusive jurisdiction of the Federal and State Courts of the State of California.
                        </p>

                        <p>
                            <span>2.</span>Any dispute, controversy or claim arising out of or relating to this
                            contract, or the breach, termination or validity thereof, shall be first settled by
                            arbitration in accordance with the Rules for Arbitration to the jurisdiction of the State of
                            California. Any decision in arbitration shall be final and binding upon the parties.
                            Notwithstanding the above, Springworks may sue in any court for infringement of its
                            proprietary or intellectual property rights.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Tnc;
